import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Cliente from '../cliente/cliente';
import Visita from '../visita/visita';
import Principal from '../principal/principal';
import UsuarioKm from '../usuarioKm/usuarioKm';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		{!props.vendedor ? (
			<Switch>
				<Route exact path='/' component={Cliente} />
				<Route path='/visita' component={Visita} />
				<Route path='/usuarioKm' component={UsuarioKm} />
				<Redirect from='*' to='/' />
			</Switch>
		) : (
			<Switch>
				<Route exact path='/' component={Principal} />
				<Redirect from='*' to='/' />
			</Switch>
		)}
	</div>
);
