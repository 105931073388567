import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import ClienteForm from './principalClienteForm';
import VisitaForm from './principalVisitaForm';

import imagemCorteva from '../assets/images/corteva.png';
import imagemPrimecrops from '../assets/images/primecrops.png';

import { 
	setModoTela, 
	initFormCliente, salvarCliente, excluirCliente, getListaCliente,
	initFormVisita, salvarVisita, excluirVisita, getListaVisita,
	salvarKmAtual
} from './principalActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';

class Principal extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getListaCliente();
        this.props.getListaVisita();
    }

    render() {
        return (
            <div>
                <Content>


					{this.props.modoTela == 'clientes' ? this.clientes() : null}
					{this.props.modoTela == 'cadastroCliente' ? (
						<ClienteForm onSubmit={this.props.salvarCliente} />
					) : null}
					
					{this.props.modoTela == 'visitas' ? this.visitas() : null}
					{this.props.modoTela == 'cadastroVisita' ? (
						<VisitaForm onSubmit={this.props.salvarVisita} />
					) : null}
                </Content>
            </div>
        )
    }

	clientes() {

		return (
			<ContentCard>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<img src={imagemCorteva} style={{ width: 140, marginBottom: 10, paddingTop: 10 }}/>
					<img src={imagemPrimecrops} style={{ width: 140 }}/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<a href='https://api-agroconsultor.infinitum-ti.com/campo_lavoura/corteva.pdf' target='_blank' style={{ width: 140, marginBottom: 10, textAlign: 'center' }}>Ver Catálogo</a>
					<a href='https://api-agroconsultor.infinitum-ti.com/campo_lavoura/primecrops.pdf' target='_blank' style={{ width: 140, marginBottom: 10, textAlign: 'center' }}>Ver Catálogo</a>
				</div>

				<ContentCardBody>

					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<LabelAndInputNumber
							label=''
							placeholder='Km Atual'
							cols='12 12 12 12'
							value={this.state.km_atual}
							onChange={(data => {
								this.setState({ ...this.state, km_atual: data.target.value });
							})}
							casas={0} />
							
						<button type='button'
							class='btn btn-block btn-outline-dark btn-xs'
							style={{ width: 90, marginTop: 22, marginBottom: 16 }}
							disabled={parseInt(FormatUtils.formatarValorBanco(this.state.km_atual, 0) || 0) <= 0}
							onClick={() => {
								if (parseInt(FormatUtils.formatarValorBanco(this.state.km_atual, 0) || 0) > 0) {
									this.props.salvarKmAtual({
										km_atual: this.state.km_atual
									}, () => this.setState({ ...this.state, km_atual: null }));
								}
							 }} >
							Salvar
						</button>
					</div>

					{this.props.listaCliente.map(item => (
						<ContentCard key={item.id}>
							<ContentCardBody>
								<div onClick={() => {
										this.props.setModoTela('cadastroCliente');
										this.props.initFormCliente({
											...item,
											area: FormatUtils.formatarValorTela(item.area, 2)
										});
									}}>

									<p style={{ fontSize: 14, padding: 2, margin: 1, fontWeight: 'bold' }}>{item.nome}</p>
									<p style={{ fontSize: 12, padding: 2, margin: 1 }}>Celular: {item.telefone || ''}</p>
											
									{(item.data_ultima_visita || item.data_proxima_visita) &&
									<>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ textAlign: 'left' }}>Última Visita</div>
											<div style={{ textAlign: 'right' }}>Próxima Visita</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>									
											<div style={{ textAlign: 'left', fontWeight: 'bold' }}>{DateFormat.formatarDataSqlParaTela(item.data_ultima_visita)}</div>
											<div style={{ textAlign: 'right', fontWeight: 'bold' }}>{DateFormat.formatarDataSqlParaTela(item.data_proxima_visita)}</div>
										</div>
									</>}
								</div>
									
								<button type='button'
									class='btn btn-block btn-outline-dark btn-xs'
									style={{ width: 80, marginTop: 8 }}
									onClick={() => {
										this.setState({ ...this.state, id_cliente: item.id });
										this.props.setModoTela('visitas');
									 }} >
									Visitas
								</button>		

							</ContentCardBody>
						</ContentCard>
					))}

				</ContentCardBody>

				<a href='#'
					 class='btn btn-success back-to-top' role='button' aria-label=''
					 style={{
						borderRadius: '50%',
						height: 44,
						width: 44,
						padding: 12,
						backgroundColor: '#1a346c'
					 }}
					 onClick={() => {
						this.props.setModoTela('cadastroCliente');
						this.props.initFormCliente({});
					 }}>
						<i class='fas fa-plus' style={{ fontSize: 16 }}></i>
				</a>
			</ContentCard>
		);
	}

	visitas() {

		return (
			<ContentCard>
				<ContentCardBody>
					<Row style={{ marginBottom: 8 }}>
						<Grid cols='12 12 12 12'>
							<Button
								text='Voltar'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('clientes')} />
						</Grid>
					</Row>

					{this.props.listaVisita.filter(item => item.id_cliente == this.state.id_cliente).map(item => (
						<ContentCard key={item.id}>
							<ContentCardBody>
								<div onClick={() => {
										// this.props.setModoTela('cadastroVisita');
										// this.props.initFormVisita({
										// 	...item
										// });
									}}>

									<p style={{ fontSize: 14, padding: 2, margin: 1, fontWeight: 'bold' }}>{DateFormat.formatarDataSqlParaTela(item.data_visita)}</p>
									<p style={{ fontSize: 13, padding: 2, margin: 1 }}>{item.descricao || ''}</p>
									
								</div>		

							</ContentCardBody>
						</ContentCard>
					))}

				</ContentCardBody>

				<a href='#'
					 class='btn btn-success back-to-top' role='button' aria-label=''
					 style={{
						borderRadius: '50%',
						height: 44,
						width: 44,
						padding: 12,
						backgroundColor: '#1a346c'
					 }}
					 onClick={() => {
						this.props.setModoTela('cadastroVisita');
						this.props.initFormVisita({ 
							id_cliente: this.state.id_cliente,
							data_visita: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()), 
						});
					 }}>
						<i class='fas fa-plus' style={{ fontSize: 16 }}></i>
				</a>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.principal.modoTela,
	listaCliente: state.principal.listaCliente.filter(item => item.id_usuario_inclusao == state.auth.usuario.id).map(item => {
		
		let visita = state.principal.listaVisita.filter(visita => visita.id_cliente == item.id).pop();

		return {
			...item,
			data_ultima_visita: visita ? visita.data_visita : null,
			data_proxima_visita: visita ? visita.data_agendamento : null
		};
	}),
	listaVisita: state.principal.listaVisita.filter(item => item.id_usuario_inclusao == state.auth.usuario.id)
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, 
	initFormCliente, salvarCliente, excluirCliente, getListaCliente,
	initFormVisita, salvarVisita, excluirVisita, getListaVisita,
	salvarKmAtual
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Principal);
