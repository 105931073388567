const INITIAL_STATE = { 
    registro: {},
    modoTela: 'clientes', 
    listaCliente: [],
    listaVisita: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PRINCIPAL_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'PRINCIPAL_CLIENTE_LISTADO':
            return { ...state, listaCliente: action.payload.data };

        case 'PRINCIPAL_VISITA_LISTADO':
            return { ...state, listaVisita: action.payload.data };

        default:
            return state;
    }
}
