import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PRINCIPAL_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

//CLIENTE

export function initFormCliente(registro = {}) {
    return [
        initialize('principalClienteForm', registro)
    ];
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente`);
    return {
        type: 'PRINCIPAL_CLIENTE_LISTADO',
        payload: request
    };
}

export function salvarCliente(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/cliente`, {
				...registro,
				id: `${getState().auth.usuario.id}_${new Date().getTime()}`,
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaCliente());
				dispatch(setModoTela('clientes'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/cliente`, {
				...registro,
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaCliente());
				dispatch(setModoTela('clientes'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirCliente(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/cliente?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaCliente());
			dispatch(setModoTela('clientes'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//VISITA

export function initFormVisita(registro = {}) {
    return [
        initialize('principalVisitaForm', registro)
    ];
}

export function getListaVisita() {
    const request = axios.get(`${consts.API_URL}/visita`);
    return {
        type: 'PRINCIPAL_VISITA_LISTADO',
        payload: request
    };
}

export function salvarVisita(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/visita`, {
				...registro,
				id: `${getState().auth.usuario.id}_${new Date().getTime()}`,
				data_visita: DateFormat.formatarDataTelaParaSql(registro.data_visita),
				data_agendamento: DateFormat.formatarDataTelaParaSql(registro.data_agendamento),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaVisita());
				dispatch(setModoTela('visitas'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/visita`, {
				...registro,
				data_visita: DateFormat.formatarDataTelaParaSql(registro.data_visita),
				data_agendamento: DateFormat.formatarDataTelaParaSql(registro.data_agendamento),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaVisita());
				dispatch(setModoTela('visitas'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirVisita(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/visita?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaVisita());
			dispatch(setModoTela('visitas'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//KM ATUAL

export function salvarKmAtual(registro, evento) {
    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/usuarioKm`, {
			...registro,
			id: `${getState().auth.usuario.id}_${new Date().getTime()}`,
			km_atual: FormatUtils.formatarValorBanco(registro.km_atual, 0),
			id_usuario_inclusao: getState().auth.usuario.id,
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			evento();
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
