import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';

import { setModoTela, initFormCliente as initForm } from './principalActions';

class PrincipalForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='telefone'
								component={LabelAndInput}
								label='Telefone'
								placeholder='Informe o telefone'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='area'
								component={LabelAndInputNumber}
								label='Área (hectares)'
								placeholder='Informe a área'
								cols='12 12 12 12'
								readOnly={readOnly}
								casas={2} />

							<Field
								name='link_maps'
								component={LabelAndInput}
								label='Localização (link Maps)'
								placeholder='Informe a localização'
								cols='12 12 12 12'
								readOnly={readOnly} />

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<div class='col-8 col-xl-8 col-xs-8 col-sm-8 col-md-8 col-lg-8'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'primary'}
									icon={'fa fa-check'} />
								)}
							</div>
							<div class='col-4 col-xl-4 col-xs-4 col-sm-4 col-md-4 col-lg-4'>
								<Button
									text='Voltar'
									type={'primary'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('clientes')} />
							</div>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

PrincipalForm = reduxForm({form: 'principalClienteForm', destroyOnUnmount: false})(PrincipalForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('principalClienteForm')(state),
	registro: state.principal.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PrincipalForm);
