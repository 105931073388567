import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ClienteReducer from '../cliente/clienteReducer';
import VisitaReducer from '../visita/visitaReducer';
import PrincipalReducer from '../principal/principalReducer';
import UsuarioKmReducer from '../usuarioKm/usuarioKmReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    cliente: ClienteReducer,
    visita: VisitaReducer,
    usuario: UsuarioReducer,
    principal: PrincipalReducer,
    usuarioKm: UsuarioKmReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
